/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios';
import { refreshAccessToken } from './refreshToken';

const { REACT_APP_API_URL } = process.env;

type DonazioniCountByIdEvento = { numero_donazioni: number };

export async function getInvoiceCount(token: string): Promise<DonazioniCountByIdEvento> {
  await refreshAccessToken();

  try {
    const response: AxiosResponse<DonazioniCountByIdEvento> = await axios.get(`${REACT_APP_API_URL}/donazioni/count`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Se l'errore è un errore Axios, puoi ottenere il messaggio dalla risposta dell'errore
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
      } else {
        // Se il messaggio non è disponibile nella risposta, gestisci come errore generico
        console.log('Errore nella chiamata API: ' + error.message);
      }
    } else {
      // Altrimenti, è un errore generico
      console.log('Errore sconosciuto: ' + error);
    }

    return { numero_donazioni: 0 };
  }
}
