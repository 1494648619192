import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface RicevutePanelProps {
  closedEvent: boolean;
}

export default function RicevutePanel({ closedEvent }: RicevutePanelProps) {
  const { control } = useFormContext();
  return (
    <div
      className="person-card-box"
      style={{
        backgroundColor: '#ffffff',
        paddingInline: 10,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 8,
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Controller
        name="qt_ricevute_cartacee"
        control={control}
        disabled={closedEvent}
        render={({ field, fieldState: { error } }) => (
          <TextField
            size="small"
            label="Ricevute Cartacee Utilizzate"
            {...field}
            error={!!error}
            helperText={error?.message}
            type="number"
          />
        )}
      />
      <Controller
        name="qt_ricevute_digitali"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            size="small"
            disabled
            label="Ricevute Digitali Utilizzate"
            {...field}
            error={!!error}
            helperText={error?.message}
            type="number"
          />
        )}
      />
    </div>
  );
}
