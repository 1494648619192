import { FormHelperText, TextField } from '@mui/material';
import { ProductToBuy } from '../../../interface/product';
import styles from './DonazioneCents.module.css';
import { useEffect, useState } from 'react';
import { formatPrice } from '../../../utils/formatPrice';

interface DonazioneCentsProps {
  product: ProductToBuy;
  onIncrementQuantity: (id: number, amount: number) => void;
}

export default function DonazioneCents({ onIncrementQuantity, product }: DonazioneCentsProps) {
  const [centsCount, setCentsCount] = useState<string>('00');

  useEffect(() => {
    onIncrementQuantity(product.id, Number(centsCount));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centsCount]);

  return (
    <div className={styles.container_cents}>
      <div className="product-info" style={{ flex: 1 }}>
        <span>{product.nome}</span>
        <span>
          {formatPrice(product.prezzo)}{' '}
          {product.quantita > 1 && `x ${product.quantita} = ${formatPrice(product.prezzo * product.quantita)}`}
        </span>
      </div>
      <div className={styles.container_cents_input}>
        <span>+ 0,</span>
        <TextField
          sx={{ marginInline: 0.4, marginTop: 0.4 }}
          inputProps={{
            style: {
              width: 30,
              height: '100%',
              fontSize: 25,
              fontWeight: 500,
            },
            maxLength: 2,
            inputMode: 'numeric',
          }}
          onBlur={() => (isNaN(Number(centsCount)) || centsCount === '' ? setCentsCount('00') : null)}
          onFocus={(e) => {
            if (centsCount === '00' || centsCount === '0') {
              setCentsCount('');
            } else {
              e.target.select();
            }
          }}
          value={centsCount}
          onChange={(e) => setCentsCount(e.target.value)}
          size="small"
          variant="standard"
        />
        <span>€</span>
      </div>
      {isNaN(Number(centsCount)) ? (
        <FormHelperText sx={{ color: 'red' }}>Inserisci un valore numerico</FormHelperText>
      ) : null}
    </div>
  );
}
