export enum QuantityReducerActionType {
  ADD_COUNT = 'ADD_COUNT',
  UPDATE_COUNT = 'UPDATE_COUNT',
  DELETE_COUNT = 'DELETE_COUNT',
}

export type QuantityCountReducerAction =
  | { type: QuantityReducerActionType.ADD_COUNT }
  | { type: QuantityReducerActionType.UPDATE_COUNT; payload: { fieldIndex: number; value: number } }
  | { type: QuantityReducerActionType.DELETE_COUNT };

export const quantityCountReducer = (state: number[], action: QuantityCountReducerAction) => {
  switch (action.type) {
    case QuantityReducerActionType.ADD_COUNT:
      return [...state, 0];
    case QuantityReducerActionType.UPDATE_COUNT:
      return state.map((v: any, index: number) => {
        if (index === action.payload.fieldIndex) {
          return action.payload.value;
        }
        return v;
      });
    case QuantityReducerActionType.DELETE_COUNT:
      return state.slice(0, -1);
    default:
      return state;
  }
};
