import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useMemo, useState } from 'react';
import { getEventiFotoByIdEvento } from '../api/getEventiFoto';
import { EventPhotoType } from '../enum/EventPhotoType';

const photoCategories = [
  { value: EventPhotoType.DDT, label: 'Documento di Trasporto' },
  { value: EventPhotoType.MERCE_DANNEGGIATA, label: 'Merce Danneggiata' },
];

export default function EventPhotos() {
  const [photoCategory, setPhotoCategory] = useState<string>(EventPhotoType.DDT);

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const idEvento = params.id;

  const { data, isLoading } = useQuery(['eventPhotos', idEvento], async () => getEventiFotoByIdEvento(idEvento || ''), {
    refetchOnWindowFocus: false,
  });

  const imageData = useMemo(() => {
    if (!data) return [];
    return data.filter((photo) => photo.type === photoCategory);
  }, [data, photoCategory]);

  if (!idEvento || idEvento === '') {
    navigate('/not-found');
  }

  if (isLoading || !data) {
    return <div>Caricamento...</div>;
  }

  return (
    <div className="container-main">
      <h1>Foto dell'Evento: {idEvento}</h1>
      <Paper sx={{ p: 2, minHeight: '100vh', width: '100%', marginTop: 2 }}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">Categoria Foto</InputLabel>
          <Select
            value={photoCategory}
            variant="filled"
            onChange={(e) => setPhotoCategory(e.target.value)}
            label="Age"
            sx={{ width: 200 }}
          >
            {photoCategories.map((category) => {
              return (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box flexGrow={1} sx={{ marginTop: 2 }}>
          <Grid container columns={{ xs: 6, sm: 8, md: 12 }} alignItems="center" justifyContent="center">
            {imageData.map((photoObject, i) => (
              <Grid item xs={12} sm={6} md={6} key={i}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 1,
                  }}
                >
                  <Zoom>
                    <img
                      style={{ maxWidth: 220, maxHeight: 220, objectFit: 'cover' }}
                      src={`data:image/png;base64,${photoObject.base64Image}`}
                      alt="evento"
                    />
                  </Zoom>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
