import { Alert, Pagination } from '@mui/material';
import { SkeletonContactList, SkeletonReport } from '../commons/Skeleton';
import DonazioneCard from './DonazioneCard';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { formatPrice } from '../../utils/formatPrice';
import { useAuth } from '../auth/AuthProvider';
import { useQuery } from 'react-query';
import { searchDonazioni } from '../../api/searchDonazioni';
import { toast } from 'react-toastify';
import { reportDonazioni } from '../../api/reportDonazioni';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { areDatesEqual } from '../../utils/dates';

const PER_PAGE = 100;

const DonazioniListPresentational = () => {
  const { token } = useAuth();
  const [pageReport, setPageReport] = useState(true);
  const [day, setDay] = useState(moment());
  const [page, setPage] = useState(1);

  const minDate = moment().subtract(90, 'days');
  const formattedDate = day.utc().format('YYYY-MM-DDT12:00:00.000[Z]');

  const {
    data: donazioniData,
    isLoading: donazioniLoading,
    error: donazioniError,
  } = useQuery(['donazioni', formattedDate, page], () => searchDonazioni(token, formattedDate, page, PER_PAGE), {
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const {
    data: reportsData,
    isLoading: reportLoading,
    error: reportError,
  } = useQuery(['reportDonazioni'], () => reportDonazioni(token), {
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const selectedDayReport = reportsData?.find((report) => areDatesEqual(report.giorno, formattedDate));

  const hasPos = sessionStorage.getItem('hasPos') === 'true';

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const report = (
    <>
      {reportLoading ? (
        <SkeletonReport />
      ) : reportError ? (
        <Alert severity="error"> Si è verificato un errore </Alert>
      ) : (
        <>
          {!selectedDayReport ? (
            <Alert severity="warning"> Non ci report per questo giorno</Alert>
          ) : (
            <div className="report-container">
              <div className="report">
                <div className="icon">
                  <EuroIcon />
                </div>
                <div className="report-quantity">
                  <p>Importo totale donazioni</p>
                  <span>{formatPrice(Number(selectedDayReport?.importo_totale_donazioni))}</span>
                </div>
              </div>
              <div className="report">
                <div className="icon">
                  <VolunteerActivismIcon />
                </div>
                <div className="report-quantity">
                  <p>Totale donazioni libere </p>
                  <span>{formatPrice(selectedDayReport?.importo_totale_donazione_libera)} </span>
                </div>
              </div>
              <div className="report">
                <div className="icon">
                  <ShoppingBasketIcon />
                </div>
                <div className="report-quantity">
                  <p>Totale donazioni per prodotti </p>
                  <span> {formatPrice(selectedDayReport?.importo_totale_prodotti)} </span>
                  <p>
                    Quantità: <strong>{selectedDayReport?.quantita_totale_prodotti}</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  const donazioni = (
    <>
      {donazioniLoading ? (
        <SkeletonContactList />
      ) : donazioniData?.data?.length ? (
        <div className="person-card-box">
          {donazioniData?.data.map((donazione) => <DonazioneCard key={donazione.id} donazione={donazione} />)}
          {donazioniData?.totalCount > donazioniData?.data?.length && (
            <Pagination
              style={{ display: 'flex', justifyContent: 'center' }}
              count={Math.ceil(donazioniData?.totalCount / PER_PAGE)}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </div>
      ) : donazioniError ? (
        <Alert severity="error"> Si è verificato un errore </Alert>
      ) : (
        <Alert severity="warning"> Non ci sono ancora donazioni</Alert>
      )}
    </>
  );

  return (
    <div className="container-main gap">
      <h1>Donazioni giornaliere</h1>
      {hasPos ? (
        <>Non sei abilitato per vedere i report</>
      ) : (
        <>
          <DatePicker
            disableFuture
            minDate={minDate}
            label="Giorno selezionato"
            format="DD/MM/YYYY"
            value={day}
            slotProps={{ textField: { size: 'small', fullWidth: true } }}
            onChange={(newValue) => newValue && setDay(newValue)}
          />
          <div className="menu-donazioni">
            <div className={pageReport ? 'selected' : 'cursor-pointer'} onClick={() => setPageReport(true)}>
              Report donazioni
            </div>
            <div className={!pageReport ? 'selected' : 'cursor-pointer'} onClick={() => setPageReport(false)}>
              Ultime donazioni
            </div>
          </div>
          {pageReport ? report : donazioni}
        </>
      )}
    </div>
  );
};

export default DonazioniListPresentational;
