import moment from 'moment';
import { Donazione } from '../../interface/donazione';
import DonazioneItem from './DonazioneItem';

interface Props {
  donazione: Donazione;
}

const DonazioneCard: React.FC<Props> = ({ donazione }) => {
  return (
    <div className="person-card">
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <h3>
            {donazione.id} - {donazione.nome_contatto} {donazione.cognome_contatto}
          </h3>
          {donazione.prodotti && donazione.prodotti.map((prodotto, i) => <DonazioneItem key={i} prodotto={prodotto} />)}
          <div className="space-between">
            <p>Pagamento:</p>
            <p>
              <strong>{donazione.metodo_pagamento}</strong>
            </p>
          </div>
          <div className="space-between">
            <p>Volontario:</p>
            <p>
              <strong>{donazione.username_volontario}</strong>
            </p>
          </div>
          <div className="space-between">
            <p>Data:</p>
            <p>
              <strong>{donazione.created_at && moment(donazione.created_at).format('DD/MM/YYYY HH:mm')}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonazioneCard;
