import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';

interface EventPanelProps {
  closedEvent: boolean;
}

export default function EventPanel({ closedEvent }: EventPanelProps) {
  const { control } = useFormContext();

  return (
    <div
      className="person-card-box"
      style={{
        backgroundColor: '#ffffff',
        paddingInline: 10,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 8,
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Controller
        name="commenti"
        control={control}
        disabled={closedEvent}
        render={({ field, fieldState: { error } }) => (
          <TextField
            multiline
            minRows={2}
            size="small"
            label="Commenti..."
            {...field}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="orario_inizio"
        control={control}
        disabled={closedEvent}
        render={({ field, fieldState: { error } }) => (
          <TimePicker
            label="Ora inizio distribuzione *"
            format="HH:mm"
            ampm={false}
            {...field}
            value={moment(field.value)}
          />
        )}
      />
      <Controller
        name="orario_fine"
        control={control}
        disabled={closedEvent}
        render={({ field, fieldState: { error } }) => (
          <TimePicker
            label="Ora fine distribuzione *"
            format="HH:mm"
            ampm={false}
            timezone="Europe/Rome"
            {...field}
            value={moment(field.value)}
          />
        )}
      />
    </div>
  );
}
