/* eslint-disable max-lines */
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import SummaryTextFieldAccordionTo from '../SummaryTextFieldAccordionTo';
import SummaryTextFieldAccordion from '../SummaryTextFieldAccordion';
import { Paper, TextField } from '@mui/material';
import { ISummaryFormData } from '../../../interface/summary';
import { useEffect, useState } from 'react';

interface ProductPanelProps {
  closedEvent: any;
  mainProduct: string;
}

export default function ProductPanel({ closedEvent, mainProduct }: ProductPanelProps) {
  const { control, getValues } = useFormContext<ISummaryFormData>();
  const [piazzeEsterneTotalIn, setPiazzeEsterneTotalIn] = useState(0);
  const [piazzeEsterneTotalOut, setPiazzeEsterneTotalOut] = useState(0);

  const [totalCountIn, setTotalCountIn] = useState<number>(0);
  const [totalCountOut, setTotalCountOut] = useState<number>(0);

  const useFormValues = () => {
    return {
      ...useWatch({ control }), // subscribe to form value updates
      ...getValues(), // always merge with latest form values
    };
  };

  const { qt_ricevuti, qt_distribuiti, qt_rimanenze, qt_non_utilizzabili } = useFormValues();

  useEffect(() => {
    setTotalCountOut(
      Number(qt_rimanenze) + Number(qt_non_utilizzabili) + Number(qt_distribuiti) + piazzeEsterneTotalOut,
    );
  }, [qt_distribuiti, qt_rimanenze, qt_non_utilizzabili, piazzeEsterneTotalOut]);

  useEffect(() => {
    setTotalCountIn(Number(qt_ricevuti) + piazzeEsterneTotalIn);
  }, [qt_ricevuti, piazzeEsterneTotalIn]);

  const setWordGender = (word: string) => {
    if (mainProduct?.endsWith('e')) {
      return word.substring(0, word.length - 1) + 'e';
    } else {
      return word;
    }
  };

  return (
    <>
      <div
        className="person-card-box"
        style={{
          backgroundColor: '#ffffff',
          padding: 10,
          borderRadius: 8,
          textAlign: 'center',
          marginTop: 20,
        }}
      >
        <Controller
          name="qt_ricevuti"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              disabled={closedEvent}
              size="small"
              label={`${mainProduct} ${setWordGender('Ricevuti')}`}
              {...field}
              error={!!error}
              type="number"
            />
          )}
        />
        {'+'}
        <SummaryTextFieldAccordion
          accordionSummaryTitle={`${mainProduct} ${setWordGender('Presi')} da altre piazze`}
          control={control}
          disabled={closedEvent}
          setPiazzeEsterneTotalIn={setPiazzeEsterneTotalIn}
        />
        {'='}
        <p style={{ margin: 0, fontSize: '0.94rem', color: 'gray', fontWeight: 600 }}>
          <span style={{ color: '#00ACDE' }}>{totalCountIn}</span> (Totale in entrata)
        </p>
      </div>
      <div
        className="person-card-box"
        style={{
          backgroundColor: '#ffffff',
          paddingInline: 10,
          paddingTop: 20,
          paddingBottom: 20,
          borderRadius: 8,
          marginTop: 20,
          justifyContent: 'center',
          textAlign: 'center',
          lineHeight: '0.6rem',
        }}
      >
        <Paper
          variant="outlined"
          style={{
            textAlign: 'center',
            height: '150px',
            padding: 10,
          }}
        >
          <span>
            <small>{`${mainProduct} ${setWordGender('Distribuiti')}`}</small>
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Controller
              name="qt_distribuiti_digitale"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  disabled
                  size="small"
                  sx={{
                    input: {
                      textAlign: 'center',
                      fontWeight: '900',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: 'black',
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '10px',
                      margin: 0,
                      marginTop: '3px',
                      lineHeight: '0.8rem',
                    },
                  }}
                  {...field}
                  error={!!error}
                  type="number"
                  variant="standard"
                  helperText="con incasso digitale"
                />
              )}
            />
            <span>+</span>
            <Controller
              name="qt_distribuiti_non_registrati"
              control={control}
              disabled={closedEvent}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  size="small"
                  sx={{
                    input: {
                      textAlign: 'center',
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '10px',
                      margin: 0,
                      marginTop: '3px',
                      color: 'rgba(0, 0, 0, 0.38)',
                      lineHeight: '0.8rem',
                    },
                  }}
                  helperText={error?.message ? error?.message : 'con donazione non registrata a donandi'}
                  {...field}
                  error={!!error}
                  type="number"
                />
              )}
            />
            <span>=</span>
            <Controller
              name="qt_distribuiti"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  disabled
                  sx={{
                    input: {
                      textAlign: 'center',
                      fontWeight: '900',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#00ACDE',
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '10px',
                      margin: 0,
                      marginTop: '3px',
                      lineHeight: '0.8rem',
                    },
                  }}
                  size="small"
                  {...field}
                  error={!!error}
                  helperText="totale"
                  type="number"
                  variant="standard"
                />
              )}
            />
          </div>
        </Paper>
        {'+'}
        <SummaryTextFieldAccordionTo
          accordionSummaryTitle={`${mainProduct} ${setWordGender('Dati')} ad altre piazze`}
          control={control}
          disabled={closedEvent}
          setPiazzeEsterneTotalOut={setPiazzeEsterneTotalOut}
        />
        {'+'}
        <Controller
          name="qt_non_utilizzabili"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginInline: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 10,
              }}
            >
              <TextField
                disabled
                sx={{
                  input: {
                    textAlign: 'center',
                    fontWeight: '900',
                    width: '70px',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#00ACDE',
                  },
                }}
                size="small"
                variant="standard"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
              <p style={{ fontSize: '0.8rem' }}>{`${mainProduct} non utilizzabili`}</p>
            </div>
          )}
        />
        {'+'}
        <Controller
          name="qt_rimanenze"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              disabled={closedEvent}
              label="Rimanenze"
              {...field}
              error={!!error}
              helperText={error?.message}
              type="number"
            />
          )}
        />
        {'='}
        <p style={{ margin: 0, fontSize: '0.94rem', color: 'gray', fontWeight: 600 }}>
          <span style={{ color: '#00ACDE' }}>{totalCountOut}</span> (Totale in uscita)
        </p>
      </div>
      <div
        className="person-card-box"
        style={{
          backgroundColor: '#ffffff',
          padding: 10,
          borderRadius: 8,
          textAlign: 'center',
          marginTop: 20,
        }}
      >
        <Controller
          name="qt_richiesta_anno_successivo"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              label={`${mainProduct} ${setWordGender('Richiesti')} per Il ${new Date().getFullYear() + 1}`}
              {...field}
              error={!!error}
              helperText={error?.message}
              type="number"
            />
          )}
        />
      </div>
    </>
  );
}
