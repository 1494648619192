import React from 'react';
import { Box } from '@mui/material';

export default function RendicontazioniButton({
  onClick,
  icon,
  label,
}: {
  onClick: () => void;
  icon: React.ReactElement;
  label: string;
}) {
  return (
    <div className="icon-button" onClick={onClick} style={{ height: 'clamp(100px, 20vh, 250px)' }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Box display="flex" justifyContent="center" alignItems="center" width={42} height={42}>
          {icon}
        </Box>
        <span
          style={{
            color: '#919191',
            fontSize: '1.3rem',
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            lineClamp: 2,
          }}
        >
          {label}
        </span>
      </Box>
    </div>
  );
}
