import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, TextField } from '@mui/material';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { useEffect, useReducer } from 'react';
import { quantityCountReducer, QuantityReducerActionType } from './lib/reducers';
import { ISummaryFormData } from '../../interface/summary';

interface SummaryTextFieldAccordionProps {
  accordionSummaryTitle: string;
  control: Control<ISummaryFormData, any>;
  disabled?: boolean;
  setPiazzeEsterneTotalIn: React.Dispatch<React.SetStateAction<number>>;
}

export default function SummaryTextFieldAccordion({
  control,
  accordionSummaryTitle,
  disabled,
  setPiazzeEsterneTotalIn,
}: SummaryTextFieldAccordionProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'qt_piazze_esterne',
  });
  const [quantityCountState, dispatch] = useReducer(
    quantityCountReducer,
    fields.map((el: any) => el.quantità),
  );

  useEffect(() => {
    setPiazzeEsterneTotalIn(quantityCountState.reduce((acc: number, curr: number) => acc + Number(curr), 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityCountState]);

  return (
    <Accordion
      sx={{
        backgroundColor: 'transparent',
        border: '1px solid rgba(118, 118, 118, 0.5)',
        boxShadow: 'none',
        borderRadius: 1,
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          fontSize: '0.9rem',
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <p style={{ margin: 0 }}>
          <span style={{ fontSize: '1rem', fontWeight: '600', color: '#00ACDE' }}>
            {quantityCountState.reduce((acc: number, curr: number) => acc + Number(curr), 0)}
          </span>{' '}
          {accordionSummaryTitle}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        {fields.map((field, index) => (
          <div
            key={field.id}
            style={{
              display: 'flex',
              gap: '0.8rem',
              marginTop: '0.7rem',
            }}
          >
            <Controller
              key={`qt_piazze_esterne.${index}.nome`}
              name={`qt_piazze_esterne.${index}.nome`}
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    size="small"
                    disabled={disabled}
                    label="Nome Piazza"
                    sx={{
                      flex: 1,
                    }}
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />
            <Controller
              key={`qt_piazze_esterne.${index}.quantità`}
              name={`qt_piazze_esterne.${index}.quantità`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  size="small"
                  disabled={disabled}
                  label="Quantità"
                  type="number"
                  sx={{
                    flex: 1,
                  }}
                  {...field}
                  onChange={(e) => {
                    dispatch({
                      type: QuantityReducerActionType.UPDATE_COUNT,
                      payload: {
                        fieldIndex: index,
                        value: Number(e.target.value),
                      },
                    });
                    field.onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
        ))}
      </AccordionDetails>
      <AccordionActions>
        <Button
          onClick={() => {
            remove(Math.max(1, fields.length - 1));
            dispatch({
              type: QuantityReducerActionType.DELETE_COUNT,
            });
          }}
          disabled={fields.length === 1 || disabled}
        >
          Cancella
        </Button>
        <Button
          onClick={() => {
            dispatch({
              type: QuantityReducerActionType.ADD_COUNT,
            });
            append({ nome: '', quantità: '' });
          }}
          disabled={disabled}
        >
          Aggiungi
        </Button>
      </AccordionActions>
    </Accordion>
  );
}
