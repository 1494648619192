import { ProdottoDonazione } from '../../interface/donazione';

interface Props {
  prodotto: ProdottoDonazione;
}

const DonazioneItem: React.FC<Props> = ({ prodotto }) => {
  return (
    <div className="space-between">
      <p>Prodotto: </p>
      <p>
        <strong>
          {prodotto.quantita} x {prodotto.nome_prodotto} = {prodotto.importo} €
        </strong>
      </p>
    </div>
  );
};

export default DonazioneItem;
