import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface ContributionPanelProps {
  closedEvent: boolean;
}

export default function ContributionPanel({ closedEvent }: ContributionPanelProps) {
  const { control } = useFormContext();
  return (
    <div
      className="person-card-box"
      style={{
        backgroundColor: '#ffffff',
        padding: 10,
        borderRadius: 8,
        textAlign: 'center',
        height: '150px',
      }}
    >
      <i>
        <small>Contributi raccolti</small>
      </i>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
        }}
      >
        <Controller
          name="qt_contributi_digitale"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              disabled
              size="small"
              sx={{
                input: {
                  textAlign: 'center',
                  fontWeight: '900',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
              }}
              FormHelperTextProps={{
                style: {
                  textAlign: 'center',
                  fontSize: '10px',
                  margin: 0,
                  marginTop: '3px',
                  lineHeight: '0.8rem',
                },
              }}
              {...field}
              error={!!error}
              type="number"
              variant="standard"
              helperText="con incasso digitale"
            />
          )}
        />
        <span>+</span>
        <Controller
          name="qt_contributi_non_registrati"
          control={control}
          disabled={closedEvent}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              {...field}
              error={!!error}
              sx={{
                input: {
                  textAlign: 'center',
                },
              }}
              FormHelperTextProps={{
                style: {
                  textAlign: 'center',
                  fontSize: '10px',
                  margin: 0,
                  marginTop: '3px',
                  color: 'rgba(0, 0, 0, 0.38)',
                  lineHeight: '0.8rem',
                },
              }}
              helperText={error?.message ? error?.message : 'con donazione non registrata a donandi'}
              type="number"
            />
          )}
        />
        <span>=</span>
        <Controller
          name="tot_contributi"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              disabled
              sx={{
                input: {
                  textAlign: 'center',
                  fontWeight: '900',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#00ACDE',
                },
              }}
              FormHelperTextProps={{
                style: {
                  textAlign: 'center',
                  fontSize: '10px',
                  margin: 0,
                },
              }}
              size="small"
              type="number"
              {...field}
              error={!!error}
              helperText="totale"
              variant="standard"
            />
          )}
        />
      </div>
    </div>
  );
}
